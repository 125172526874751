import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {WorkupApiService} from '@matchsource/api/workup';
import {
  ClearWorkupsInProgress,
  DeleteWorkupInProgress,
  GetWorkupsInProgress,
  RemoveWorkupInProgress,
} from './workup-in-progress.action';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  removeEntities,
} from '@matchsource/store/core';
import {compose} from '@ngxs/store/operators';
import {WorkupInProgressModel} from '@matchsource/models/workup';
import {UppercaseSourceType} from '@matchsource/models/source';

type WorkupInProgressStateModel = EntitiesStateModel<WorkupInProgressModel> & {patientId: MsApp.Guid | null};

const initState = (): WorkupInProgressStateModel => ({
  ...defaultEntitiesState<WorkupInProgressModel>(),
  patientId: null,
});

@State<WorkupInProgressStateModel>({
  name: 'workupsInProgress',
  defaults: initState(),
})
@Injectable()
export class WorkupsInProgressState {
  @Selector([WorkupsInProgressState])
  static loading({loading}: WorkupInProgressStateModel) {
    return loading;
  }

  @Selector([WorkupsInProgressState])
  static loaded({loaded}: WorkupInProgressStateModel) {
    return loaded;
  }

  @Selector([WorkupsInProgressState])
  static ids({ids}: WorkupInProgressStateModel) {
    return ids;
  }

  @Selector([WorkupsInProgressState])
  static entities({entities}: WorkupInProgressStateModel) {
    return entities;
  }

  private static workupsBySource(workupSourceType: UppercaseSourceType) {
    return createSelector([WorkupsInProgressState.entities], (entities: MsApp.Dictionary<WorkupInProgressModel>) =>
      Object.values(entities).filter(({sourceType}) => sourceType === workupSourceType)
    );
  }

  static cords() {
    return this.workupsBySource(UppercaseSourceType.Cord);
  }

  static donors() {
    return this.workupsBySource(UppercaseSourceType.Donor);
  }

  @Selector([WorkupsInProgressState.ids])
  static size(ids: unknown[]) {
    return ids.length;
  }

  @Selector([WorkupsInProgressState.donors()])
  static donorIdList(donorWorkups: WorkupInProgressModel[]): MsApp.Guid[] {
    return donorWorkups.map(({sourceId}) => sourceId);
  }

  @Selector([WorkupsInProgressState.cords()])
  static cordIdList(cordWorkups: WorkupInProgressModel[]): MsApp.Guid[] {
    return cordWorkups.map(({sourceId}) => sourceId);
  }

  @Selector([WorkupsInProgressState])
  static patientId({patientId}: WorkupInProgressStateModel): MsApp.Guid | null {
    return patientId;
  }

  static has(workupId: string | number) {
    const workupIdStr = `${workupId}`;

    return createSelector([this.ids], (ids: Array<string | number>) => ids.some(id => `${id}` === workupIdStr));
  }

  static workupById(workupId: number) {
    return createSelector(
      [this.entities],
      (entities: MsApp.Dictionary<WorkupInProgressModel>) => entities[workupId] ?? null
    );
  }

  constructor(
    private readonly api: WorkupApiService,
    private readonly store: Store
  ) {}

  @Action(GetWorkupsInProgress)
  getWorkupsInProgress(ctx: StateContext<WorkupInProgressStateModel>, {patientId}: GetWorkupsInProgress) {
    ctx.patchState({
      loading: true,
      patientId,
    });

    return this.api.getWorkupsInProgress(patientId).pipe(
      tap(workupsInProgress => {
        ctx.setState(
          compose(
            addOrReplaceEntities<WorkupInProgressModel, WorkupInProgressStateModel>('id', workupsInProgress),
            loadedEntities(true)
          )
        );
      }),
      finalize(() => ctx.setState(loadingEntities(false)))
    );
  }

  @Action(RemoveWorkupInProgress)
  removeWorkupInProgress(ctx: StateContext<WorkupInProgressStateModel>, {workupId}: RemoveWorkupInProgress) {
    ctx.setState(removeEntities<WorkupInProgressStateModel>(workupId));
  }

  @Action(DeleteWorkupInProgress)
  deleteWorkupInProgress(ctx: StateContext<WorkupInProgressStateModel>, {workupId}: DeleteWorkupInProgress) {
    return this.api
      .removeWorkupInProgress(workupId)
      .pipe(switchMap(() => this.store.dispatch(new RemoveWorkupInProgress(workupId))));
  }

  @Action(ClearWorkupsInProgress)
  clear(ctx: StateContext<WorkupInProgressStateModel>) {
    ctx.setState(initState());
  }
}
