import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  removeEntities,
} from '@matchsource/store/core';
import {WorkupInProgressModel} from '@matchsource/models/workup';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {DonorWorkupApiService} from '@matchsource/api/donor-workup';
import {
  ClearDonorWorkupsInProgress,
  DeleteDonorWorkupInProgress,
  GetDonorWorkupsInProgress,
  RemoveDonorWorkupInProgress,
} from './donor-workup-in-progress.actions';

type WorkupInProgressStateModel = EntitiesStateModel<WorkupInProgressModel> & {patientId: MsApp.Guid | null};

const initState = (): WorkupInProgressStateModel => ({
  ...defaultEntitiesState<WorkupInProgressModel>(),
  patientId: null,
});

@State<WorkupInProgressStateModel>({
  name: 'donorWorkupsInProgress',
  defaults: initState(),
})
@Injectable()
export class DonorWorkupInProgressState {
  @Selector([DonorWorkupInProgressState])
  static entities({entities}: WorkupInProgressStateModel): MsApp.Dictionary<WorkupInProgressModel> {
    return entities;
  }

  @Selector([DonorWorkupInProgressState])
  static ids({ids}: WorkupInProgressStateModel): string[] {
    return ids;
  }

  @Selector([DonorWorkupInProgressState.ids])
  static size(ids: unknown[]) {
    return ids.length;
  }

  @Selector([DonorWorkupInProgressState.entities])
  static workups(entities: MsApp.Dictionary<WorkupInProgressModel>): WorkupInProgressModel[] {
    return Object.values(entities);
  }

  @Selector([DonorWorkupInProgressState.entities])
  static donorIdList(entities: MsApp.Dictionary<WorkupInProgressModel>): MsApp.Guid[] {
    return Object.values(entities).map(({sourceId}) => sourceId);
  }

  static has(workupId: string | number) {
    const workupIdStr = `${workupId}`;

    return createSelector([this.ids], (ids: Array<string | number>) => ids.some(id => `${id}` === workupIdStr));
  }

  static workupById(workupId: number) {
    return createSelector(
      [this.entities],
      (entities: MsApp.Dictionary<WorkupInProgressModel>) => entities[workupId] ?? null
    );
  }

  constructor(
    private readonly donorWorkupApiService: DonorWorkupApiService,
    private readonly store: Store
  ) {}

  @Action(GetDonorWorkupsInProgress)
  getWorkupsInProgress(ctx: StateContext<WorkupInProgressStateModel>, {patientId}: GetDonorWorkupsInProgress) {
    ctx.patchState({
      loading: true,
      patientId,
    });

    return this.donorWorkupApiService.getWorkupsInProgress(patientId).pipe(
      tap(workupsInProgress => {
        ctx.setState(
          compose(
            addOrReplaceEntities<WorkupInProgressModel, WorkupInProgressStateModel>('id', workupsInProgress),
            loadedEntities(true)
          )
        );
      }),
      finalize(() => ctx.setState(loadingEntities(false)))
    );
  }

  @Action(RemoveDonorWorkupInProgress)
  removeWorkupInProgress(ctx: StateContext<WorkupInProgressStateModel>, {workupId}: RemoveDonorWorkupInProgress) {
    ctx.setState(removeEntities<WorkupInProgressStateModel>(workupId));
  }

  @Action(DeleteDonorWorkupInProgress)
  deleteWorkupInProgress(ctx: StateContext<WorkupInProgressStateModel>, {workupId}: DeleteDonorWorkupInProgress) {
    return this.donorWorkupApiService
      .removeWorkupInProgress(workupId)
      .pipe(switchMap(() => this.store.dispatch(new RemoveDonorWorkupInProgress(workupId))));
  }

  @Action(ClearDonorWorkupsInProgress)
  clear(ctx: StateContext<WorkupInProgressStateModel>) {
    ctx.setState(initState());
  }
}
