import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {ClearWorkupsInProgress, DeleteWorkupInProgress, GetWorkupsInProgress} from './workup-in-progress.action';
import {WorkupsInProgressState} from './workup-in-progress.state';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {WorkupInProgressModel} from '@matchsource/models/workup';
import {
  ClearDonorWorkupsInProgress,
  DeleteDonorWorkupInProgress,
  GetDonorWorkupsInProgress,
  DonorWorkupInProgressState,
} from '@matchsource/store/donor-workup-in-progress';
import {map} from 'rxjs/operators';
import {USER_PERMISSIONS} from '@matchsource/models/permissions';
import {PermissionsService} from '@matchsource/store/permissions';

@Injectable({
  providedIn: 'root',
})
export class WorkupInProgressService {
  private get patientId(): MsApp.Guid | null {
    return this.store.selectSnapshot(WorkupsInProgressState.patientId);
  }

  readonly sourceIdList$: Observable<MsApp.Guid[]>;

  readonly cordIdList$: Observable<MsApp.Guid[]>;

  readonly donorIdList$: Observable<MsApp.Guid[]>;

  readonly size$: Observable<number>;

  private loaded: Promise<void> | null = null;

  constructor(
    private readonly store: Store,
    private readonly permissions: PermissionsService
  ) {
    this.cordIdList$ = this.store.select(WorkupsInProgressState.cordIdList);
    this.donorIdList$ = combineLatest([
      this.store.select(DonorWorkupInProgressState.donorIdList),
      this.store.select(WorkupsInProgressState.donorIdList),
    ]).pipe(map(([donorIdList, donorLegacyIdList]) => [...donorIdList, ...donorLegacyIdList]));

    this.sourceIdList$ = combineLatest([this.donorIdList$, this.cordIdList$]).pipe(
      map(([donorIdList, cordIdList]) => [...donorIdList, ...cordIdList])
    );

    this.size$ = combineLatest([
      this.store.select(WorkupsInProgressState.size),
      this.store.select(DonorWorkupInProgressState.size),
    ]).pipe(
      map((sizes: number[]) =>
        sizes.reduce((acc, size) => {
          acc += size;

          return acc;
        }, 0)
      )
    );
  }

  async load(patientId: MsApp.Guid, force = false): Promise<void> {
    const currentPatientId = this.patientId;
    if (currentPatientId === patientId && !force) {
      return this.loaded;
    }

    if (currentPatientId !== patientId) {
      this.clear();
    }

    if (!this.permissions.hasPermissions(USER_PERMISSIONS.WORKUP_IN_PROGRESS_ALLOW)) {
      this.loaded = null;
      return;
    }

    this.loaded = firstValueFrom(
      this.store.dispatch([new GetWorkupsInProgress(patientId), new GetDonorWorkupsInProgress(patientId)])
    );

    return this.loaded;
  }

  getWorkupById(workupId: number): WorkupInProgressModel | null {
    const workup: WorkupInProgressModel | null = this.store.selectSnapshot(
      DonorWorkupInProgressState.workupById(workupId)
    );

    if (workup) {
      return workup;
    }

    return this.store.selectSnapshot(WorkupsInProgressState.workupById(workupId));
  }

  has(workupId: number | string): boolean {
    if (this.store.selectSnapshot(DonorWorkupInProgressState.has(workupId))) {
      return true;
    }

    return this.store.selectSnapshot(WorkupsInProgressState.has(workupId));
  }

  delete(workupId: number): Promise<void> {
    const workup = this.getWorkupById(workupId);
    if (!workup) {
      return;
    }

    const isCommonWorkup = workup.sourceType === 'CORD' || workup.formCode === 'SECEWU';
    const isDonor = !isCommonWorkup && workup.sourceType === 'DONOR';

    return firstValueFrom(
      this.store.dispatch([
        ...(isCommonWorkup ? [new DeleteWorkupInProgress(workupId)] : []),
        ...(isDonor ? [new DeleteDonorWorkupInProgress(workupId)] : []),
      ])
    );
  }

  getBySourceId(workupSourceId: MsApp.Guid): WorkupInProgressModel {
    const entities = this.store.selectSnapshot(WorkupsInProgressState.entities);

    const workup = Object.values(entities).find(({sourceId}) => sourceId === workupSourceId);

    if (workup !== undefined) {
      return workup;
    }

    const donorWorkups = this.store.selectSnapshot(DonorWorkupInProgressState.entities);
    return Object.values(donorWorkups).find(({sourceId}) => sourceId === workupSourceId);
  }

  private clear(): void {
    this.loaded = null;
    this.store.dispatch([new ClearWorkupsInProgress(), new ClearDonorWorkupsInProgress()]);
  }
}
