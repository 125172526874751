export class GetWorkupsInProgress {
  static readonly type = '[Workup] Load workups in progress';

  constructor(public readonly patientId: MsApp.Guid) {}
}

export class ClearWorkupsInProgress {
  static readonly type = '[Workup] Clear workups in progress';
}

export class RemoveWorkupInProgress {
  static readonly type = '[Workup] Remove workup in progress';

  constructor(public readonly workupId: number | string) {}
}

export class DeleteWorkupInProgress {
  static readonly type = '[Workup] Delete workup in progress';

  constructor(public readonly workupId: number) {}
}
