export class GetDonorWorkupsInProgress {
  static readonly type = '[DonorWorkup] Load workups in progress';

  constructor(public readonly patientId: MsApp.Guid) {}
}

export class ClearDonorWorkupsInProgress {
  static readonly type = '[DonorWorkup] Clear workups in progress';
}

export class RemoveDonorWorkupInProgress {
  static readonly type = '[DonorWorkup] Remove workup in progress';

  constructor(public readonly workupId: number | string) {}
}

export class DeleteDonorWorkupInProgress {
  static readonly type = '[DonorWorkup] Delete workup in progress';

  constructor(public readonly workupId: number) {}
}
