import {StateOperator} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {EntitiesStateModel} from '../model';
import {ensureArray} from '../internal';
import {ExtractEntityModel} from '../utils';

type IdType = number | string;

export function removeEntities<R extends EntitiesStateModel<any>, T = ExtractEntityModel<R>>(
  keys: IdType | IdType[]
): StateOperator<R> {
  // @TODO: Remove "as any"
  return (state: Readonly<R>) => {
    const idList = ensureArray(keys).map(id => `${id}`);
    const {entities: currentEntities, ids: currentIds} = state;

    const newEntities: MsApp.Dictionary<T> = Object.entries(currentEntities)
      .filter(([key]) => !idList.includes(key))
      .reduce((acc, [key, entity]) => {
        acc[key] = entity;

        return acc;
      }, {});
    const newIds = currentIds.filter(id => !idList.includes(`${id}`));

    return patch<EntitiesStateModel<T>>({
      entities: newEntities,
      ids: newIds,
    })(state) as any;
  };
}
